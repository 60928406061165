import { useState, useEffect } from "react";
import React from "react";
import clsx from "classnames";
import AuthContext from "../AuthContext";
import SignedOutHeader from "../SignedOutHeader/SignedOutHeader";
import Footer from "../Footer/Footer";
import { usePostHog } from "posthog-js/react";
import { Link, useNavigate } from "react-router-dom";
import ReferralBanner from "../ReferralBanner/ReferralBanner";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Badge } from "@/components/ui/badge";
import { Slider } from "@/components/ui/slider";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ArrowLeft } from "lucide-react";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Check, Loader2 } from "lucide-react";
import { cn } from "@/lib/utils";

export default function CustomPricing() {
  const navigate = useNavigate();
  const posthog = usePostHog();
  const { t } = useTranslation();
  // pass useState() the initial value

  // machineType can be "ondemand", "100gb", "200gb", "1tb"
  const [machineType, setMachineType] = useState("100gb");
  const [paymentPeriod, setPaymentPeriod] = useState("week");
  const [vmPaymentType, setVmPaymentType] = useState("subscription");
  const [hoursPaymentType, setHoursPaymentType] = useState("subscription");
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [checkoutEmail, setCheckoutEmail] = useState(null);
  const [hours, setHours] = useState(40);
  const machineTypeLabel = {
    ondemand: "On-demand",
    "100gb": "100 GB",
    "200gb": "200 GB",
  };
  const [selectedPC, setSelectedPC] = useState("private");
  const [storage, setStorage] = useState("100");

  const loadScript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const RAZORPAY_SCRIPT_URL = "https://checkout.razorpay.com/v1/checkout.js";

  const hoursSubscriptionPriceId =
    process.env.NODE_ENV === "development"
      ? {
          month: "price_1OlEwWSAcn2IgTItg9eqME0Z",
          week: "price_1OlEw6SAcn2IgTIttxw05mbK",
        }
      : {
          month: "price_1OmJqrSAcn2IgTItNc9VNvGA",
          week: "price_1OmJqrSAcn2IgTItsEFemcYj",
        };

  // The code here will only run in development mode
  const machineTypePaymentLink =
    process.env.NODE_ENV === "development"
      ? {
          "100gb": {
            payment: {
              week: "price_1OlEWbSAcn2IgTItPKlCzqP3",
              month: "price_1OiLUaSAcn2IgTItxEZnAaKE",
            },
            subscription: {
              week: "price_1OlEVgSAcn2IgTIt3qo8KhCs",
              month: "price_1OiLVTSAcn2IgTItL1T8naGJ",
            },
          },
          "200gb": {
            payment: {
              week: "price_1OhyAaSAcn2IgTItK3KDbHOq",
              month: "price_1OiLV2SAcn2IgTItqWOf4BDA",
            },
            subscription: {
              week: "price_1OhyAxSAcn2IgTItLZwGVJ09",
              month: "price_1OiLWPSAcn2IgTItjYKsgpfP",
            },
          },
        }
      : {
          "100gb": {
            payment: {
              week: "price_1OmJq3SAcn2IgTItyuznQrVI",
              month: "price_1OmJqISAcn2IgTItSSzuzoc4",
            },
            subscription: {
              week: "price_1OmJq3SAcn2IgTItNrLumyWE",
              month: "price_1OmJqISAcn2IgTIt4hQPs20M",
            },
          },
          "200gb": {
            payment: {
              week: "price_1OmJqZSAcn2IgTItvVIMRb1J",
              month: "price_1OmJqCSAcn2IgTItYkyBff7X",
            },
            subscription: {
              week: "price_1OmJqZSAcn2IgTItbXlXJAyo",
              month: "price_1OmJqCSAcn2IgTItOt2z15vG",
            },
          },
        };

  const machineTypePaymentPrice = {
    "100gb": {
      subscription: {
        week: "150",
        month: "550",
      },
      payment: {
        week: "160",
        month: "580",
      },
    },
    "200gb": {
      subscription: {
        week: "300",
        month: "1100",
      },
      payment: {
        week: "320",
        month: "1160",
      },
    },
  };
  const { user, signOut, signIn, userEmail, paymentGateway } =
    React.useContext(AuthContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    loadScript(RAZORPAY_SCRIPT_URL).then((loaded) => {
      if (!loaded) {
        console.log(
          "Failed to load Razorpay SDK. Please check your internet connection and try again."
        );
      }
    });
  }, []);

  async function handleBuyClick(
    priceId,
    payment_mode,
    quantity,
    payment_gateway,
    isHourlyPlan
  ) {
    if (!user) {
      signIn();
    }
    posthog?.capture("purchase_plan", {
      priceId,
      payment_mode,
      quantity,
      paymentPeriod,
      vmPaymentType,
      paymentPeriod,
      hoursPaymentType,
      userEmail,
    });
    setCheckoutLoading(true);
    let products = [
      {
        priceId: priceId,
        isHourlyPlan: isHourlyPlan,
        adjustableQuantity: false,
        quantity: quantity,
        email: checkoutEmail,
        vmMachineType: machineType,
        vmPaymentPeriod: paymentPeriod,
        vmPaymentType: vmPaymentType,
        hoursPaymentPeriod: paymentPeriod,
        hoursPaymentType: hoursPaymentType,
      },
    ];
    if (!isHourlyPlan && hours) {
      let vmHoursPriceId = null;
      if (payment_mode === "subscription") {
        vmHoursPriceId = hoursSubscriptionPriceId[paymentPeriod];
      }
      products.push({
        priceId: vmHoursPriceId,
        isHourlyPlan: true,
        adjustableQuantity: false,
        quantity: hours,
        email: checkoutEmail,
        vmMachineType: machineType,
        vmPaymentPeriod: paymentPeriod,
        vmPaymentType: vmPaymentType,
        hoursPaymentPeriod: paymentPeriod,
        hoursPaymentType: payment_mode,
      });
    }
    await fetch(
      payment_gateway === "stripe"
        ? process.env.NODE_ENV === "development"
          ? "https://1ku3nifl2e.execute-api.ap-south-1.amazonaws.com/default/getStripeCheckout-dev-hello"
          : "https://v1wapno6ch.execute-api.ap-south-1.amazonaws.com/prod/getStripeCheckout-prod-hello"
        : process.env.NODE_ENV === "development"
        ? "https://9mljq6r4e3.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-dev-hello"
        : "https://d58cycm35h.execute-api.ap-south-1.amazonaws.com/default/getRazorpayCheckout-prod-hello",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: user.signInUserSession.idToken.jwtToken,
        },
        body: JSON.stringify({
          payment_mode: payment_mode,
          products: products,
        }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.checkout_url) {
          window.location.href = data.checkout_url;
        } else if (data.order_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id,
            amount: data.amount, // Amount should be in currency subunits
            currency: "INR",
            name: "GameAway",
            order_id: data.order_id,
            handler: function (response) {
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        } else if (data.subscription_id && payment_gateway === "razorpay") {
          const options = {
            key: data.key_id, // Enter the Key ID generated from the Razorpay Dashboard
            subscription_id: data.subscription_id,
            name: "GameAway",
            handler: function (response) {
              navigate("/");
            },
            prefill: {
              email: checkoutEmail,
            },
            theme: {
              color: "#231955",
            },
          };
          const paymentObject = new window.Razorpay(options);
          paymentObject.open();
        }
        setCheckoutLoading(false);
      })
      .catch((error) => {
        console.error("Error:", error);
        setCheckoutLoading(false);
      });
  }

  function getHoursPrice(hours, paymentType = hoursPaymentType) {
    let discountedPrice = 0;
    if (paymentType === "subscription") {
      if (hours < 10) {
        discountedPrice = hours * 50;
      } else if (hours < 20) {
        discountedPrice = hours * 45;
      } else {
        discountedPrice = hours * 40;
      }
    } else {
      if (hours < 10) {
        discountedPrice = hours * 52.5;
      } else if (hours < 20) {
        discountedPrice = hours * 47.5;
      } else {
        discountedPrice = hours * 42.5;
      }
    }

    return discountedPrice;
  }

  return (
    <div className="pt-0 max-w-6xl px-6 my-0 mx-auto min-h-screen">
      <SignedOutHeader />
      <div className="w-full py-6 flex-col justify-start items-center gap-12 inline-flex overflow-hidden">
        <div className="self-stretch flex-col justify-start items-center gap-8 flex">
          <div className="self-stretch justify-start items-end gap-8 inline-flex">
            <div className="w-full flex-col justify-start items-start gap-5 inline-flex">
              <div className="w-full self-stretch justify-between items-start gap-4 flex md:flex-row flex-col">
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-1 inline-flex">
                  <div className="self-stretch text-neutral-50 text-3xl font-bold leading-9">
                    Custom Plan
                  </div>
                  <div className="self-stretch text-[#94969c] text-base font-normal leading-normal">
                    Configure your preferences below
                  </div>
                </div>
                <Tabs
                  defaultValue={paymentPeriod}
                  className="mt-4 w-full md:w-fit"
                  onValueChange={setPaymentPeriod}
                >
                  <TabsList className="grid w-full grid-cols-2 h-fit rounded-xl border border-[#161b26] p-1 bg-[#1f242f] bg-opacity-0">
                    <TabsTrigger
                      value="week"
                      className="px-3 py-1.5 rounded-lg data-[state=active]:bg-[#1f242f]"
                    >
                      Weekly
                    </TabsTrigger>
                    <TabsTrigger
                      value="month"
                      className="px-3 py-1.5 rounded-lg gap-2 flex data-[state=active]:bg-[#1f242f] justify-between"
                    >
                      Monthly
                    </TabsTrigger>
                  </TabsList>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full gap-8 flex md:flex-row flex-col">
          <div className="grow shrink gap-4 flex flex-col w-full md:w-3/5">
            <Card className="self-stretch bg-[#0c111d] rounded-2xl shadow-[0px_4px_6px_-2px_rgba(0,0,0,0.05)] shadow-lg border border-[#1f242f] flex-col justify-start items-start flex overflow-hidden">
              <CardHeader className="self-stretch p-4 border-b border-[#1f242f] flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch justify-start items-start gap-3 inline-flex">
                  <div className="w-8 h-8 p-1 bg-gray-800 rounded-full justify-center items-center flex overflow-hidden">
                    <div className="w-6 h-6 text-center text-neutral-50 text-lg font-semibold font-['Bricolage Grotesque'] leading-7">
                      1
                    </div>
                  </div>
                  <div className="flex-col justify-between items-start flex">
                    <div className="justify-start items-start">
                      <div className="text-neutral-50 text-2xl font-semibold">
                        Choose Your PC
                      </div>
                    </div>
                    <div className="self-stretch text-[#94969c] text-sm font-medium">
                      {t("select_pc_option")}
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardContent className="p-4 w-full">
                <RadioGroup
                  value={selectedPC}
                  onValueChange={setSelectedPC}
                  className="gap-3 w-full"
                >
                  <Card
                    className={cn(
                      selectedPC === "quick"
                        ? "bg-[#1f242f] border-0"
                        : "border-0 cursor-pointer rounded-xl",
                      "border-0 rounded-xl"
                    )}
                    onClick={() => setSelectedPC("quick")}
                  >
                    <div className="flex items-center justify-between p-4">
                      <div className="grow shrink basis-0 text-neutral-50 text-xl font-semibold font-['Bricolage Grotesque'] leading-7">
                        Quick Play PC
                      </div>
                      <RadioGroupItem value="quick" />
                    </div>
                    <div className="self-stretch px-3 pb-3 justify-start items-start gap-3 inline-flex">
                      <div className="grow shrink basis-0 self-stretch px-2 flex-col justify-start items-start gap-2 inline-flex">
                        <div className="self-stretch justify-start items-center gap-2 inline-flex">
                          <Check className="w-5 h-5 p-1 relative bg-[#333741] rounded-full  overflow-hidden"></Check>
                          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                            <div className="self-stretch text-[#94969c] text-sm font-normal font-['Bricolage Grotesque'] leading-tight">
                              {t("pre_installed_and_shared")}
                            </div>
                          </div>
                        </div>
                        <div className="self-stretch justify-start items-center gap-2 inline-flex">
                          <Check className="w-5 h-5 p-1 relative bg-[#333741] rounded-full  overflow-hidden"></Check>
                          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                            <div className="self-stretch text-[#94969c] text-sm font-normal font-['Bricolage Grotesque'] leading-tight">
                              {t("cloud_saves_only")}
                            </div>
                          </div>
                        </div>
                        <div className="self-stretch justify-start items-center gap-2 inline-flex">
                          <Check className="w-5 h-5 p-1 relative bg-[#333741] rounded-full  overflow-hidden"></Check>
                          <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                            <div className="self-stretch text-[#94969c] text-sm font-normal font-['Bricolage Grotesque'] leading-tight">
                              {t("add_playtime_anytime")}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>

                  <Card
                    className={cn(
                      selectedPC === "private"
                        ? "bg-[#1f242f] border-0"
                        : "border-0 cursor-pointer rounded-xl",
                      "border-0 rounded-xl"
                    )}
                    onClick={() => setSelectedPC("private")}
                  >
                    <div className="flex items-center justify-between p-4">
                      <div className="grow shrink basis-0 text-neutral-50 text-xl font-semibold leading-7">
                        Private Gaming PC
                      </div>
                      <div className="flex items-center gap-4 justify-between">
                        <Badge
                          variant="default"
                          className="px-2.5 py-0.5 bg-fuchsia-900 rounded-full border border-fuchsia-700 justify-center items-center gap-2.5 flex text-fuchsia-200 text-xs font-semibold font-['Bricolage Grotesque'] leading-none"
                        >
                          Popular
                        </Badge>
                        <RadioGroupItem value="private" />
                      </div>
                    </div>
                    <div className="w-full self-stretch px-3 pb-3 justify-start items-start gap-3 inline-flex">
                      <div className="w-full grow shrink basis-0 flex-col justify-start items-start gap-3 inline-flex">
                        <div className="w-full self-stretch px-2 flex-col justify-start items-start gap-2 flex">
                          <div className="self-stretch justify-start items-center gap-2 inline-flex">
                            <Check className="w-5 h-5 p-1 relative bg-[#333741] rounded-full  overflow-hidden"></Check>
                            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                              <div className="self-stretch text-[#94969c] text-sm font-normal font-['Bricolage Grotesque'] leading-tight">
                                {t("install_your_games")}
                              </div>
                            </div>
                          </div>
                          <div className="self-stretch justify-start items-center gap-2 inline-flex">
                            <Check className="w-5 h-5 p-1 relative bg-[#333741] rounded-full  overflow-hidden"></Check>
                            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                              <div className="self-stretch text-[#94969c] text-sm font-normal font-['Bricolage Grotesque'] leading-tight">
                                {t("cloud_local_saves")}
                              </div>
                            </div>
                          </div>
                          <div className="self-stretch justify-start items-center gap-2 inline-flex">
                            <Check className="w-5 h-5 p-1 relative bg-[#333741] rounded-full  overflow-hidden"></Check>
                            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                              <div className="self-stretch text-[#94969c] text-sm font-normal font-['Bricolage Grotesque'] leading-tight">
                                {t("shareable_with_friends")}
                              </div>
                            </div>
                          </div>
                          <div className="self-stretch justify-start items-center gap-2 inline-flex">
                            <Check className="w-5 h-5 p-1 relative bg-[#333741] rounded-full  overflow-hidden"></Check>
                            <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                              <div className="self-stretch text-[#94969c] text-sm font-normal font-['Bricolage Grotesque'] leading-tight">
                                {t("add_playtime_anytime")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className={cn(
                            selectedPC === "private"
                              ? "bg-[#333741]"
                              : "bg-[#1f242f]",
                            "h-[1px] w-full "
                          )}
                        ></div>
                        <div className="self-stretch justify-between items-center inline-flex">
                          <div className="text-right text-neutral-50 text-sm font-medium font-['Bricolage Grotesque'] leading-[14px]">
                            Pick your storage
                          </div>
                          <Select
                            value={machineType}
                            onValueChange={setMachineType}
                          >
                            <SelectTrigger className="max-w-32 h-10 px-3 py-2 bg-[#1f242f] rounded-xl border border-[#333741] justify-between items-center inline-flex overflow-hidden">
                              <SelectValue placeholder="Select storage" />
                            </SelectTrigger>
                            <SelectContent>
                              <SelectItem value="100gb">100 GB</SelectItem>
                              <SelectItem value="200gb">200 GB</SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      </div>
                    </div>
                  </Card>
                </RadioGroup>
              </CardContent>
            </Card>
            <Card className="self-stretch bg-[#0c111d] rounded-2xl shadow-[0px_4px_6px_-2px_rgba(0,0,0,0.05)] shadow-lg border border-[#1f242f] flex-col justify-start items-start flex overflow-hidden">
              <CardHeader className="self-stretch p-4 border-b border-[#1f242f] flex-col justify-start items-start gap-8 flex">
                <div className="self-stretch justify-start items-start gap-3 inline-flex">
                  <div className="w-8 h-8 p-1 bg-gray-800 rounded-full justify-center items-center flex overflow-hidden">
                    <div className="w-6 h-6 text-center text-neutral-50 text-lg font-semibold font-['Bricolage Grotesque'] leading-7">
                      2
                    </div>
                  </div>
                  <div className="flex-col justify-between items-start flex">
                    <div className="justify-start items-start">
                      <div className="text-neutral-50 text-2xl font-semibold">
                        Add gaming hours
                      </div>
                    </div>
                    <div className="self-stretch text-[#94969c] text-sm font-medium">
                      {t("choose_how_many_hours")}
                    </div>
                  </div>
                </div>
              </CardHeader>
              <CardContent className="p-6 pb-8 w-full">
                <div className="space-y-3 w-full">
                  <div className="flex justify-between items-start">
                    <span className="text-base text-muted-foreground">
                      Hours per {paymentPeriod}
                    </span>
                    <span className="text-base font-semibold text-foreground">
                      {hours} hours
                    </span>
                  </div>

                  <Slider
                    value={[hours]}
                    onValueChange={(v) => setHours(v[0])}
                    max={50}
                    min={1}
                    step={1}
                    className="w-full cursor-pointer"
                  />

                  <div className="flex justify-between items-start">
                    <span className="text-sm text-muted-foreground">1h</span>
                    <span className="text-sm text-muted-foreground">50h</span>
                  </div>
                </div>
              </CardContent>
            </Card>
          </div>
          <div className="h-fit grow shrink p-5 bg-[#0c111d] rounded-3xl shadow-2xl border border-[#1f242f] flex-col justify-start items-start gap-6 inline-flex w-full md:w-2/5">
            <div className="self-stretch justify-start items-start gap-4 inline-flex">
              <div className="flex-col justify-start items-start gap-0.5 inline-flex">
                <div className="self-stretch text-neutral-50 text-xl font-semibold font-['Bricolage Grotesque'] leading-7">
                  Summary
                </div>
                <div className="self-stretch text-[#94969c] text-sm font-normal font-['Bricolage Grotesque'] leading-[14px]">
                  Your total cost
                </div>
              </div>
            </div>
            <div className="self-stretch h-fit flex-col justify-start items-start gap-3 flex">
              {selectedPC === "private" && (
                <div className="self-stretch justify-start items-start gap-2 inline-flex">
                  <div className="grow shrink basis-0 flex-col justify-start items-start inline-flex">
                    <div className="text-neutral-50 text-base font-medium font-['Bricolage Grotesque'] leading-normal">
                      Private Gaming PC
                    </div>
                    <div className="text-[#94969c] text-sm font-medium font-['Bricolage Grotesque'] leading-tight">
                      {machineTypeLabel[machineType]}
                    </div>
                  </div>
                  <div className="text-neutral-50 text-base font-medium font-['Bricolage Grotesque'] leading-normal">
                    ₹
                    {
                      machineTypePaymentPrice[machineType]["subscription"][
                        paymentPeriod
                      ]
                    }
                  </div>
                </div>
              )}
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-neutral-50 text-base font-medium font-['Bricolage Grotesque'] leading-normal">
                  {hours} hours
                </div>
                <div className="text-neutral-50 text-base font-medium font-['Bricolage Grotesque'] leading-normal">
                  <span
                    className={cn(
                      hours > 10 ? "inline-flex" : "hidden",
                      "line-through pr-2"
                    )}
                  >
                    ₹{hours * 50}
                  </span>
                  ₹{getHoursPrice(hours, "subscription")}
                </div>
              </div>
            </div>
            <div className="self-stretch justify-between items-center inline-flex">
              <div className="text-neutral-50 text-xl font-medium font-['Bricolage Grotesque'] leading-7">
                Total
              </div>
              <div className="text-neutral-50 text-xl font-bold font-['Bricolage Grotesque'] leading-7">
                ₹
                {selectedPC === "private"
                  ? parseInt(
                      machineTypePaymentPrice[machineType]["subscription"][
                        paymentPeriod
                      ]
                    ) + parseInt(getHoursPrice(hours, "subscription"))
                  : parseInt(getHoursPrice(hours, "subscription"))}{" "}
                + 18% GST
              </div>
            </div>
            <div className="self-stretch justify-start items-start gap-3 inline-flex">
              {checkoutLoading ? (
                <Button disabled className="h-12 w-full rounded-full">
                  <Loader2 className="animate-spin" />
                  Loading
                </Button>
              ) : (
                <Button
                  variant="default"
                  className="text-neutral-50 grow shrink basis-0 h-12 px-4 py-2 bg-fuchsia-700 rounded-full shadow-[0px_1px_2px_0px_rgba(9,9,11,0.05)] shadow-[inset_0px_-2px_0px_0px_rgba(9,9,11,0.05)] shadow-[inset_0px_0px_0px_1px_rgba(9,9,11,0.20)] justify-center items-center gap-2 flex overflow-hidden"
                  onClick={() =>
                    selectedPC === "private"
                      ? handleBuyClick(
                          machineTypePaymentLink[machineType][vmPaymentType][
                            paymentPeriod
                          ],
                          "subscription",
                          1,
                          paymentGateway,
                          false
                        )
                      : handleBuyClick(
                          hoursSubscriptionPriceId[paymentPeriod],
                          "subscription",
                          hours,
                          paymentGateway,
                          true
                        )
                  }
                >
                  Pay Now
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
